import { BreadcrumbNavigation } from '@hp/atomic';
import { LoginRegister, LoginRegisterType } from '@hp/auth';
import { HideOnDesktop, Layout, PageLayout, Row } from '@hp/layout';
import { Navigation } from '@hp/navigation';
import { QueryParams, RouteNameType } from '@hp/seo';
import { Trans } from '@lingui/macro';
import { NextPage, NextPageContext } from 'next';
import React from 'react';

type LoginProps = {
  redirectTo?: RouteNameType;
};

const Login: NextPage<LoginProps> = ({ redirectTo }) => {
  const breadCrumb = (
    <BreadcrumbNavigation
      goBackLink="landing"
      testId="auth.loginRegister.button.back"
    >
      <Trans id="auth.loginRegister.breadcrumb">
        Přihlášení/Vytvoření účtu
      </Trans>
    </BreadcrumbNavigation>
  );

  return (
    <Layout>
      <Navigation hideLogoOnNarrow>
        <Row>
          <HideOnDesktop>{breadCrumb}</HideOnDesktop>
        </Row>
      </Navigation>
      <PageLayout.default breadCrumb={breadCrumb}>
        <LoginRegister redirectTo={redirectTo} type={LoginRegisterType.LOGIN} />
      </PageLayout.default>
    </Layout>
  );
};

Login.getInitialProps = async ({ query }: NextPageContext) => {
  return {
    redirectTo: query[QueryParams.redirectTo],
  } as LoginProps;
};

export default Login;
